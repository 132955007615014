<template>
  <div
    v-click-outside="onClickOutsideColumn"
    :id="operationsActionsMenuAnchorID"
    :class="{
      'en-cours-column__is-conwip': !!selectedConwipGroup,
    }"
    class="machine-column machine"
  >
    <div v-if="!loading" class="machine-inprogress">
      <template v-if="pg_ops.length > 0">
        <div
          v-show="hasOperationsAbove"
          class="sticky-control top-control"
          @click="() => insideContainerScrollTo()"
        >
          {{ $t("operation.controls.top") }}
        </div>

        <div
          v-scroll.self="updateCalculationsDebounced"
          ref="scrollContainerWrapperRef"
          class="operations-scroll-container-wrapper keep-scrollbar"
        >
          <div class="operations-scroll-container" ref="scrollContainerRef">
            <template v-if="!!selectedConwipGroup && !!mc.ticketsGauge">
              <ConwipTicketsGaugeTicketsArea
                v-for="area in displayedTicketsGaugesArea"
                :key="area"
                :type="getTicketsAreaTypeByName(area)"
                has-left-border
              >
                <template #append-header>
                  {{ getTicketsCountByAreaText(area) }}
                </template>

                <SchedulingOperationWIPCard
                  v-for="(op, index) in opsByTicketsGaugeArea[area]"
                  :key="`${op.op_id}-${op.day_date}`"
                  :intersection-observer-root="scrollContainerWrapperRef"
                  v-bind="getOperationProps(op, index)"
                  v-on="operationsCardsCommonListeners"
                  @update-theoric-date="updateTheoricDate"
                />
              </ConwipTicketsGaugeTicketsArea>
            </template>

            <template v-else>
              <template v-for="(operations, index) in operationsSets">
                <OperationsCardsGroupWrapper
                  v-if="operations[0].batch_id"
                  :key="operations[0].batch_name"
                  :count="operations.length"
                  :is-selected="isSelectedOperationsGroup(operations)"
                  :class="CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS"
                  :operations-actions-menu-props="{
                    ...operationsActionsMenuProps,
                    showActionsMenu: isShownActionsMenu(operations),
                  }"
                  :data-testid="`${TEST_IDS.SCHEDULING_OPERATIONS_CARDS_GROUP_WRAPPER_PREFIX}${operations[0].batch_id}`"
                  @click="() => selectOperations(operations, true)"
                  @move-operations="onMoveOperation"
                >
                  <template #prepend-top-left>
                    <OplitIcon
                      :stroke="variables.newPrimaryDark2"
                      name="squares"
                      size="20px"
                    />
                  </template>

                  <template #top-left>
                    {{ operations[0].batch_name }}
                  </template>

                  <template #append-top-left>
                    <IsSavingSpinner
                      :is-changing="havePendingChangesOperations(operations)"
                      :is-saving="areSavingOperations(operations)"
                      :is-selected="isSelectedOperationsGroup(operations)"
                      :has-error="haveErrorsOnSaveOperations(operations)"
                    />

                    <OperationManualSort :operations="operations" />
                  </template>

                  <template #bottom-left>
                    {{ getGroupDetails(operations) }}
                  </template>

                  <SchedulingOperationWIPCard
                    v-for="(op, index) in operations"
                    :key="`${op.op_id}-${op.day_date}`"
                    :intersection-observer-root="scrollContainerWrapperRef"
                    v-bind="getOperationProps(op, index)"
                    v-on="operationsCardsCommonListeners"
                    is-op-from-group
                    @update-theoric-date="updateTheoricDate"
                  />
                </OperationsCardsGroupWrapper>

                <OperationsCardsGroupWrapper
                  v-else-if="operations[0][GROUP_KEY]"
                  :key="operations[0][GROUP_KEY]"
                  :count="operations.length"
                  :is-selected="isSelectedOperationsGroup(operations)"
                  :class="CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS"
                  :operations-actions-menu-props="{
                    ...operationsActionsMenuProps,
                    showActionsMenu: isShownActionsMenu(operations),
                  }"
                  :data-testid="`${TEST_IDS.SCHEDULING_OPERATIONS_CARDS_GROUP_WRAPPER_PREFIX}${operations[0][GROUP_KEY]}`"
                  class="bg-newLayerBackground"
                  @click="() => selectOperations(operations, true)"
                  @move-operations="onMoveOperation"
                >
                  <template #top-left>
                    <span class="text-newPrimaryRegular">
                      {{
                        getReadableImportParsingRuleValue(
                          operations[0][GROUP_KEY],
                        )
                      }}
                    </span>
                  </template>

                  <template #append-top-left>
                    <IsSavingSpinner
                      :is-changing="havePendingChangesOperations(operations)"
                      :is-saving="areSavingOperations(operations)"
                      :is-selected="isSelectedOperationsGroup(operations)"
                      :has-error="haveErrorsOnSaveOperations(operations)"
                    />

                    <OperationManualSort :operations="operations" />
                  </template>

                  <template #bottom-left>
                    {{ getGroupDetails(operations) }}
                  </template>

                  <SchedulingOperationWIPCard
                    v-for="(op, index) in operations"
                    :key="`${op.op_id}-${op.day_date}`"
                    :intersection-observer-root="scrollContainerWrapperRef"
                    v-bind="getOperationProps(op, index)"
                    v-on="operationsCardsCommonListeners"
                    is-op-from-group
                    @update-theoric-date="updateTheoricDate"
                  />
                </OperationsCardsGroupWrapper>

                <SchedulingOperationWIPCard
                  v-else
                  :key="`${operations[0].op_id}-${operations[0].day_date}`"
                  :intersection-observer-root="scrollContainerWrapperRef"
                  v-bind="getOperationProps(operations[0], index)"
                  v-on="operationsCardsCommonListeners"
                  :operations-actions-menu-props="{
                    ...operationsActionsMenuProps,
                    showActionsMenu: isShownActionsMenu(operations),
                  }"
                  @update-theoric-date="updateTheoricDate"
                />
              </template>
            </template>
          </div>
        </div>

        <div
          v-show="hasOperationsBelow"
          class="sticky-control bottom-control"
          @click="() => insideContainerScrollTo('bottom')"
        >
          {{ $t("operation.controls.bottom") }}
        </div>
      </template>

      <div
        v-else-if="!!mc.ticketsGauge"
        class="operations-scroll-container-wrapper"
      >
        <ConwipTicketsGaugeTicketsArea
          v-for="area in filteredTicketsGaugeAreas"
          :key="area"
          :type="getTicketsAreaTypeByName(area)"
          has-left-border
        >
          <template #append-header>
            0 / {{ getMaxTicketsByArea(area) }}
          </template>
        </ConwipTicketsGaugeTicketsArea>
      </div>
    </div>

    <EnCoursColumnSectorBlock
      :sector="mc"
      :class="{
        'has-operations': pg_ops.length,
      }"
      :data-testid="`${TEST_IDS.PILOTING_EN_COURS__COLUMN__SECTOR}-${mc.id}`"
    >
      <template v-slot="{sectorName}">
        <div class="d-flex flex-column pa-2">
          <div class="fd-flex-center gap-2">
            <v-tooltip location="bottom">
              <template v-slot:activator="{props}">
                <h5
                  v-bind="props"
                  class="text-grey-900 semi-bold text-ellipsis flex-1 text-16"
                >
                  {{ sliceToMaxLength(sectorName, 25) }}
                  <DevHelper mini :absolute="{top: '-4px', left: '8px'}">
                    ({{ mc.secteur_id }})
                  </DevHelper>
                </h5>
              </template>
              <span>{{ sectorName }}</span>
            </v-tooltip>

            <FButtonIcon
              v-if="
                !!selectedConwipGroup &&
                currentPermissions.scheduling.update_conwip_configuration
              "
              :icon-fill="variables.newSubText"
              :tooltip="$t('EnCoursColumn.tickets_gauge_button_tooltip')"
              icon="ticket-bar"
              size="32"
              icon-size="18"
              is-oplit-icon
              square
              @click="() => openConwipTicketsGaugeSidebar(mc)"
            />
          </div>

          <v-tooltip location="start">
            <template v-slot:activator="{props}">
              <div
                v-bind="props"
                class="d-flex items-center gap-2 text-grey-500 fbody-2"
              >
                <span>{{ $t("scheduling.capa") }} :</span>
                {{ getDisplayedMachineValue(formattedPgCapa) }}
              </div>
            </template>

            <span>
              <strong>{{ $t("scheduling.capa") }} :</strong>
              {{ getDisplayedMachineValue(formattedPgCapa) }}
            </span>
          </v-tooltip>

          <template v-if="!!selectedConwipGroup">
            <v-spacer />

            <FButton
              v-if="hasOperatorsOrMachines && !hideSectorBlockItems"
              outlined
              @click="() => $emit('machine-name-clicked')"
            >
              {{ $t("EnCoursColumn.detailed_view_button_text") }}
            </FButton>
          </template>
        </div>

        <EnCoursColumnSectorWIPRate
          :ongoing="ongoingOpsTotalNbPieces.value"
          :operations-count="ofsCount"
          :sector="sectorWithInferedUnit"
          :min-wip="pg_min_wip"
          :max-wip="pg_max_wip"
        />
      </template>
    </EnCoursColumnSectorBlock>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  inject,
  ref,
  computed,
  PropType,
  watch,
  onUnmounted,
} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import {useI18n} from "vue-i18n";
import {
  usePGWatcher,
  usePGComputedProperties,
  usePGSubscriptions,
} from "@/composables/pgComposable";
import {
  calculateOfDelay,
  pgOpsMapFn,
  getOPUnit,
  type DailyLoadWithSimulationData,
  toSafeString,
  OF_STATUS,
  getOperationStatus,
  getReadableImportParsingRuleValue,
} from "@oplit/shared-module";
import {
  operationComputedFIFO,
  operationComputedStagnation,
} from "@/tscript/utils/schedulingUtils";
import SchedulingOperationWIPCard from "@/components/Scheduling/Operations/SchedulingOperationWIPCard.vue";
import OperationManualSort from "@/components/Scheduling/Operations/OperationManualSort.vue";
import {
  DATE_DEFAULT_FORMAT,
  PRIORITY_RULES_STORAGE_IDENTIFIER,
  CSS_OPERATION_CARD_CLASS,
  ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
  CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
  OPERATION_CARD_V_LAZY_MIN_HEIGHT,
  TEST_IDS,
} from "@/config/constants";
import {
  SchedulingOperation,
  OpenConwipTicketsGaugeSidebarFunction,
  SectorLike,
  ConwipTicketsGaugeType,
} from "@/interfaces";
import {useSchedulingStore} from "@/stores/schedulingStore";

import {useMainStore} from "@/stores/mainStore";
import {useStorage} from "@vueuse/core";
import EnCoursColumnSectorBlock from "@/components/Scheduling/Piloting/EnCoursColumnSectorBlock.vue";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import ConwipTicketsGaugeTicketsArea from "@/components/Scheduling/Conwip/ConwipTicketsGaugeTicketsArea.vue";
import FButtonIcon from "@/components/Global/Homemade/Buttons/FButtonIcon.vue";
import {usePermissionsStore} from "@/lib/stores/permissionsStore";
import OperationsCardsGroupWrapper from "../Operations/OperationsCardsGroupWrapper.vue";

import {useSelectedOperations} from "@/stores/selectedOperationsStore";
import useComputeQuantityUnit from "@/composables/useComputeQuantityUnit";
import IsSavingSpinner from "@/components/Scheduling/Operations/IsSavingSpinner.vue";
import {type OperationsActionsMenuProps} from "@/components/Scheduling/Operations/OperationsActionsMenu.vue";
import EnCoursColumnSectorWIPRate from "./EnCoursColumnSectorWIPRate.vue";

// TODO: components shortage icon display for OperationsCardsGroupWrapper ?

numeral.locale("fr");

/**
 * this key is used to determine
 */
const SORT_KEY = "_sortIndex";
const GROUP_KEY = "_groupName";

export default defineComponent({
  name: "en-cours-column",
  components: {
    SchedulingOperationWIPCard,
    OperationManualSort,
    EnCoursColumnSectorBlock,
    FButton,
    ConwipTicketsGaugeTicketsArea,
    FButtonIcon,
    OperationsCardsGroupWrapper,
    IsSavingSpinner,
    EnCoursColumnSectorWIPRate,
  },
  props: {
    mc: {type: Object, default: () => ({} as SectorLike)},
    import_id: {type: String, default: ""},
    filters: {type: Array, default: () => []},
    search: {type: String, default: ""},
    ops: {type: Array, default: () => []},
    // toggle for the stagnation display within the OperationCards
    showStagnation: {type: Boolean, default: false},
    // the fields (defined with arbitrary strings) that are hidden within the OperationCards
    hiddenOperationCardsInfos: {type: Array, default: () => []},
    //informs if a request is pending in pg to get the ops
    selectedStatus: {type: Array, default: () => []},
    /**
     * array of 2 numbers representing respectively
     * the current index of this instance within the parent array
     * and the total amount of instances within the parent array
     */
    indexTotalCount: {
      type: Array as PropType<number[]>,
      default: () => [-1, -1],
    },
    isOperationOngoing: {type: Boolean, default: false},
    hideSectorBlockItems: {type: Boolean, default: false},
    isConwipDetailed: {type: Boolean, default: false},
    preventOperationsMoves: {
      type: [Boolean, String],
      default: false,
    },
  },
  setup(props, {emit}) {
    const {t} = useI18n();
    const {pg_init, pg_subscribe} = usePGSubscriptions();
    const schedulingStore = useSchedulingStore();
    const {sendSchedulingLoadEventToBackend} = schedulingStore;
    const {
      selectedSimulation,
      schedulingCurrentGroupBy,
      pgOpsModifications,
      shouldHandleOPDuration,
      selectedConwipGroup,
    } = storeToRefs(schedulingStore);
    const {currentPermissions} = storeToRefs(usePermissionsStore());
    const {
      selectedOperations,
      formattedSelectedOperationsForRetrocompatibility,
      selectedOperationsIDs,
      hasOverlayOpened,
      selectedOperationsWithBatchID,
    } = storeToRefs(useSelectedOperations());
    const {
      havePendingChangesOperations,
      areSavingOperations,
      haveErrorsOnSaveOperations,
      setSelectableOperations,
      toggleSelectedOperations,
      isSelectedOperationsGroup,
      getOperationsAggregatedQuantities,
    } = useSelectedOperations();
    const {operationComputedQuantity, operationComputedOpQuantityUnit} =
      useComputeQuantityUnit();

    const openConwipTicketsGaugeSidebar =
      inject<OpenConwipTicketsGaugeSidebarFunction>(
        "openConwipTicketsGaugeSidebar",
      );
    const priorityRulesStorageIdentifier = inject<string>(
      "priorityRulesStorageIdentifier",
      PRIORITY_RULES_STORAGE_IDENTIFIER,
    );

    const priorityRules = useStorage(priorityRulesStorageIdentifier, [
      "client_delay",
    ]);
    const isManualSortingEnabled = useStorage(
      ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
      false,
    );

    const mainStore = useMainStore();
    const {userData, apiClient, variables, stationsAndMachinesTagsGroupedById} =
      storeToRefs(mainStore);

    const orderedTicketsGaugeAreas = [
      "underload_tickets_count",
      "default_ideal_tickets_count",
      "overload_moderate_tickets_count",
      "overload_critical_tickets_count",
    ];

    const pg_ops = ref<SchedulingOperation[]>([]);
    const scrollContainerWrapperRef = ref<Element>(null);
    const scrollContainerRef = ref<Element>(null);
    const mountedOps = ref<number>(0);
    const pg_max_wip = ref<number>(null);
    const scrollContainerHeight = ref<number>(0);
    const hasOperationsAbove = ref<boolean>(false);
    const hasOperationsBelow = ref<boolean>(false);
    const resizeObserver = ref<ResizeObserver>(null);

    const today = computed(() => moment().format(DATE_DEFAULT_FORMAT));

    const parsedPeriod = computed(() => ({
      startDate: today.value,
      endDate: today.value,
    }));
    const sortedOngoingOps = computed<SchedulingOperation[]>(() => {
      const sortedOPs = getSortedOperations(pg_ops.value);
      emit(
        "operations-sorted",
        props.mc.id || props.mc.secteur_id,
        sortedOPs.map(({op_id}) => op_id),
      );
      return sortedOPs;
    });
    const sortedOngoingOpsIds = computed(() =>
      sortedOngoingOps.value.map((op) => op.op_id),
    );

    const unbatchedOngoingOps = computed<SchedulingOperation[]>(() =>
      sortedOngoingOps.value.filter(({batch_id}) => !batch_id),
    );
    // for certain cases we need to reverse due to the column being flex-reverse'd
    const reverseOrderedTicketsGaugeAreas = computed(() =>
      [...orderedTicketsGaugeAreas].reverse(),
    );
    const filteredTicketsGaugeAreas = computed(() => {
      if (!props.mc.ticketsGauge) return [];

      const {has_underload, has_overload} = props.mc.ticketsGauge;

      return orderedTicketsGaugeAreas.filter((area) => {
        if (area === "underload" && !has_underload) return false;
        if (area.startsWith("overload") && !has_overload) return false;
        return !!props.mc.ticketsGauge[area];
      });
    });
    const displayedTicketsGaugesArea = computed(() =>
      reverseOrderedTicketsGaugeAreas.value.filter(
        (area) => opsByTicketsGaugeArea.value[area],
      ),
    );
    const opsByTicketsGaugeArea = computed(() => {
      if (!props.mc.ticketsGauge) {
        return {
          overload_critical_tickets_count: sortedOngoingOps.value,
        };
      }

      const clone = [...sortedOngoingOps.value].reverse();

      const operationsByArea: Record<string, SchedulingOperation[]> = {};

      for (const area of filteredTicketsGaugeAreas.value) {
        if (props.mc.ticketsGauge[area])
          operationsByArea[area] = clone.splice(0, props.mc.ticketsGauge[area]);
      }

      if (clone.length > 0)
        operationsByArea.overload_critical_tickets_count = clone;

      return operationsByArea;
    });
    const sectorTree = computed(() => {
      if (!props.mc?.secteur_id || !props.mc?.secteur_collection) return {};
      const [sectorTree] =
        stationsAndMachinesTagsGroupedById.value[props.mc.secteur_id] || [];
      return sectorTree || {};
    });
    const operationsActionsMenuAnchorID = computed(
      () => `operations-actions-menu__anchor-${props.mc.secteur_id}`,
    );
    const operationsActionsMenuProps = computed<OperationsActionsMenuProps>(
      () => {
        const [currentIndex, totalItems] = props.indexTotalCount;

        const preventVerticalMoves = [true, "vertical"].includes(
          props.preventOperationsMoves,
        );

        return {
          isOperationOngoing: props.isOperationOngoing,
          hideEditDialog: true,
          hideVerticalArrows:
            !isManualSortingEnabled.value || preventVerticalMoves,
          showBatchModal: true,
          teleport: `:has(> #${operationsActionsMenuAnchorID.value})`,
          displaySide:
            currentIndex > 1 && currentIndex === totalItems ? "left" : "right",
          backgroundColor: "newPrimaryRegular",
          arrowsState: {
            state: {
              Left: currentIndex > 1,
              Right: currentIndex !== totalItems,
              Up:
                !preventVerticalMoves &&
                !selectedOperationsIDs.value.includes(
                  sortedOngoingOpsIds.value[0],
                ),
              Down:
                !preventVerticalMoves &&
                !selectedOperationsIDs.value.includes(
                  sortedOngoingOpsIds.value.at(-1),
                ),
            },
          },
        };
      },
    );
    const operationsCardsCommonProps = computed(() => ({
      showStagnation: props.showStagnation,
      selectedOps: formattedSelectedOperationsForRetrocompatibility.value,
      hiddenInfos: props.hiddenOperationCardsInfos,
      operationsActionsMenuProps: operationsActionsMenuProps.value,
    }));
    const operationsCardsCommonListeners = computed(() => ({
      "operation-mounted": onOperationMounted,
      "move-operation": onMoveOperation,
      "change-status": onUpdateOperation,
      "change-priority": onUpdateOperation,
      "select-card": onSelectCard,
    }));
    const determineOpIndexOfQtyLimit = computed(() => {
      if (!pg_max_wip.value) return -1;
      let targetIdx = -1,
        totalQte = 0;
      for (let i = sortedOngoingOps.value.length - 1; i >= 0; i--) {
        totalQte += +(sortedOngoingOps.value[i].quantite || 0);
        if (totalQte >= pg_max_wip.value) {
          targetIdx = i;
          break;
        }
      }
      return targetIdx;
    });
    const hasOperatorsOrMachines = computed(
      () =>
        props.mc.operateurs?.length > 0 || props.mc.machine_tags?.length > 0,
    );
    const operationsBatches = computed(() => {
      const opsByBatchName = _.groupBy(
        sortedOngoingOps.value.filter(({batch_name}) => batch_name),
        "batch_name",
      );

      const opsByBatchNameMap = new Map(Object.entries(opsByBatchName));

      return opsByBatchNameMap;
    });
    const ofsCount = computed<number>(
      () => Object.keys(_.groupBy(pg_ops.value, "of_id")).length,
    );
    const operationsSets = computed<SchedulingOperation[][]>(() => {
      let unbatchedOperationsSets = unbatchedOngoingOps.value.map((op) => [op]);

      if (schedulingCurrentGroupBy.value?.field) {
        const operationsByGroupField = _.chain(unbatchedOngoingOps.value)
          .groupBy(
            (operation) =>
              operation[schedulingCurrentGroupBy.value.field] ||
              t("global.other_capitalized", 2),
          )
          .mapValues((ops, groupName) =>
            ops.map((op) => ({
              ...op,
              [GROUP_KEY]: groupName,
            })),
          )
          .value();

        unbatchedOperationsSets = Object.values(operationsByGroupField);
      }

      const operationsBatchesSets = Array.from(
        operationsBatches.value.values(),
      );

      const operationsSets = [
        ...unbatchedOperationsSets,
        ...operationsBatchesSets,
      ].sort((a, b) => a.at(-1)[SORT_KEY] - b.at(-1)[SORT_KEY]);

      return operationsSets;
    });

    const {canLoadCapa} = usePGComputedProperties({
      parsedPeriod,
      simulation: selectedSimulation.value,
    });
    const sectorWithInferedUnit = computed<SectorLike>(() => {
      if (props.mc.unite) return props.mc;

      // required for machines
      const unite = operationComputedOpQuantityUnit(pg_ops.value[0]);

      return {
        ...props.mc,
        unite,
      };
    });
    // estimated card height based on configuration options; used to determine hasOperationsBelow/hasOperationsAbove
    const operationCardEstHeight = computed<number>(() => {
      const rootFontSize = 16;
      let cardHeight = OPERATION_CARD_V_LAZY_MIN_HEIGHT;
      if (props.showStagnation) cardHeight += rootFontSize;
      if (props.hiddenOperationCardsInfos) cardHeight += rootFontSize * 2;
      return cardHeight;
    });

    function getSortedOperations(
      ops: SchedulingOperation[],
    ): (SchedulingOperation & {sortIndex: number})[] {
      // OPL-7469 : sorting on-going ops first
      let sortingArr: ((o: SchedulingOperation) => unknown)[] = [
        (o: SchedulingOperation) =>
          getOperationStatus(o) === OF_STATUS.ON_GOING ? 1 : 0,
      ];
      let sortingOrder: ("asc" | "desc")[] = ["asc"];
      let initialPositionOffset = 0;

      if (isManualSortingEnabled.value) {
        sortingArr.unshift((o) => o?.wip_order ?? Infinity);
        sortingOrder.unshift("desc");
        initialPositionOffset++;
      }

      if (priorityRules.value.length > 0) {
        sortingArr.push(
          ...priorityRules.value
            .map((rule) => {
              return (o: SchedulingOperation) => {
                const {
                  erp_date,
                  new_date,
                  day_date,
                  max_date_of,
                  theoric_date,
                  fast_track,
                  of_delta,
                  first_active_op_theoric_date,
                  first_active_op_planned_date,
                  is_day_date_modified,
                  all_ops_done,
                  nb_ops_done,
                } = o || ({} as SchedulingOperation);

                switch (rule) {
                  case "priority":
                    return fast_track || undefined;
                  case "client_delay":
                    return calculateOfDelay({
                      initial_date: erp_date,
                      new_date,
                      day_date,
                      theoric_date: max_date_of || theoric_date,
                      first_active_op_theoric_date,
                      first_active_op_planned_date,
                      is_day_date_modified,
                      real_date_delta: of_delta,
                      all_ops_done,
                      nb_ops_done,
                    });
                  case "op_delay":
                    return new_date || day_date;
                  case "stagnation":
                    return [
                      operationComputedFIFO(o) ?? -1,
                      operationComputedStagnation(o) ?? -1,
                    ];
                  default:
                    return new_date ?? day_date;
                }
              };
            })
            .flat(),
        );
        sortingOrder.push(
          ...priorityRules.value
            .map((rule) => {
              if (rule === "stagnation")
                return ["asc", "desc"] as ("asc" | "desc")[];
              return ["op_delay", "priority"].includes(rule) ? "desc" : "asc";
            })
            .flat(),
        );
      }

      // OPL-7752 : we want to sort by op_order right after op_delay
      if (priorityRules.value.includes("op_delay")) {
        const opOrderSortingInsertionIndex =
          priorityRules.value.indexOf("op_delay") +
          Math.max(initialPositionOffset, 1);

        // We add one to ensure the op_order sorting is done after the op_delay sorting
        // Without it OPs are sorted by op_order before op_delay, which means that sorting by date simply does not work in the app
        // OPL-7849
        sortingArr.splice(
          opOrderSortingInsertionIndex + 1,
          0,
          (o) => +o?.op_order ?? undefined,
        );
        sortingOrder.splice(opOrderSortingInsertionIndex + 1, 0, "desc");
      }

      sortingArr.push((o: SchedulingOperation) => +o?.op_sequence || 0);
      sortingOrder.push("desc");

      const sortedOPs = _.orderBy(ops, sortingArr, sortingOrder).map(
        (op, index) => ({
          ...op,
          [SORT_KEY]: index,
        }),
      );

      return sortedOPs;
    }
    function propagateOpsChanges(
      updatesList: Partial<DailyLoadWithSimulationData>[],
    ) {
      const tempPgOps = pg_ops.value.map((op: any) => {
        const matchOnNewUpdates = updatesList.find(
          ({op_id}) => op_id === op.op_id,
        );
        if (matchOnNewUpdates) return matchOnNewUpdates;
        else return op;
      });
      pg_ops.value = tempPgOps;
      pgOpsModifications.value = tempPgOps;
    }
    // returns a string without unwanted spaces between "nullish" values
    function stringifyArray(
      array: Array<number | string>,
      separator = " ",
    ): string {
      return array.filter(Boolean).join(separator);
    }

    function getDisplayedMachineValue(value: string) {
      if (pg_ops.value.length < 1) return "-";
      const [first] = pg_ops.value;
      return stringifyArray([
        value,
        getOPUnit(first, sectorWithInferedUnit.value),
      ]);
    }
    function onSelectCard(operation?: SchedulingOperation) {
      selectOperations([operation], false);
    }
    function onMoveOperation({code}: {code: string}) {
      emit("move-operations", code, sortedOngoingOpsIds.value);
    }
    /**
     * moving an operation triggers an outside click therefore removing all selected cards
     * this function is to prevent this behaviour upon clicking on a menu arrow
     */
    function shouldPreventOutsideClick(eventTarget: HTMLElement) {
      if (!eventTarget) return false;

      if (
        [
          "feather-arrow-left",
          "feather-arrow-right",
          CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
        ].some((key) => eventTarget.classList?.contains(key))
      )
        return true;
      return shouldPreventOutsideClick(eventTarget.parentElement);
    }
    async function onClickOutsideColumn({target}: {target: HTMLElement}) {
      if (!selectedOperations.value.length) return;
      if (hasOverlayOpened.value) return;
      if (shouldPreventOutsideClick(target)) return;
      selectedOperations.value = [];
      onSelectCard();
    }
    function onOperationMounted() {
      mountedOps.value += 1;
    }
    async function onUpdateOperation({
      update,
      operation,
    }: {
      update: Record<string, unknown>;
      operation: SchedulingOperation;
    }) {
      if (!operation) return;

      // visually apply modifications before the backend response
      const opIndex = pg_ops.value.findIndex(
        (op) => op.op_id === operation.op_id,
      );
      if (opIndex === -1) return;
      pg_ops.value[opIndex] = {
        ...pg_ops.value[opIndex],
        ...update,
      };

      //OPL-4456 : nouveau format des évènements d'ordo
      const {updated_values} = await sendSchedulingLoadEventToBackend({
        data: [{initial: operation, update}],
        should_return_updated_values: true,
        should_handle_op_duration: shouldHandleOPDuration.value,
      });
      const mappedValues = pgOpsMapFn(updated_values, {
        keepOpsDone: true,
      });
      propagateOpsChanges(mappedValues);
    }
    function getOperationProps(operation: SchedulingOperation, index: number) {
      return {
        ...operationsCardsCommonProps.value,
        id: operation.op_id,
        order: index + 1,
        isOngoingLimit: index === determineOpIndexOfQtyLimit.value,
        doNotRenderLazy: index > sortedOngoingOps.value.length - 5,
        class: "scheduling-operation-card__en-cours",
        operation,
      };
    }
    function getMaxTicketsByArea(area: string): string | number {
      return area === "overload_critical_tickets_count"
        ? "∞"
        : props.mc.ticketsGauge?.[area];
    }
    function getTicketsCountByAreaText(area: string) {
      return `${
        opsByTicketsGaugeArea.value[area].length
      } / ${getMaxTicketsByArea(area)}`;
    }
    function getTicketsAreaTypeByName(area: string): ConwipTicketsGaugeType {
      switch (area) {
        case "underload_tickets_count":
          return "underload";
        case "overload_moderate_tickets_count":
          return "overload-moderate";
        case "overload_critical_tickets_count":
          return "overload-critical";
        default:
          return "ideal";
      }
    }

    function onOperationsGrouped(groupedOPsIDs: string[][]): void {
      emit(
        "operations-grouped",
        props.mc.id || props.mc.secteur_id,
        groupedOPsIDs,
      );
    }
    function selectOperations(
      operations: SchedulingOperation[],
      isGroup: boolean,
    ) {
      const options = {} as Parameters<typeof toggleSelectedOperations>[1];

      if (props.isConwipDetailed) options.operationKey = "tag_id";

      const operationsSetsIds = operationsSets.value.map((arr) =>
        arr.map(({op_id}) => op_id),
      );

      const selectedOperationsBySetsIdsIndex = _.groupBy(
        selectedOperations.value,
        ({op_id}) =>
          operationsSetsIds.findIndex((opIds) => opIds.includes(op_id)),
      );

      const hasSomeGroupNotAllItsOperationsSelected = Object.entries(
        selectedOperationsBySetsIdsIndex,
      ).some(([index, ops]) => ops.length !== operationsSetsIds[index]?.length);

      /**
       * selecting a group: we reset `selectedOperations` if
       * we had previously selected single operations within a group
       */
      if (isGroup) {
        if (hasSomeGroupNotAllItsOperationsSelected)
          selectedOperations.value = [];
      } else {
        /**
         * selecting a single operation: we reset `selectedOperations` if we previously selected a group
         */
        const currentGroup = operationsSetsIds.find((opIds) =>
          opIds.includes(operations[0]?.op_id),
        );

        if (
          selectedOperations.value.some(
            ({op_id}) => !currentGroup?.includes(op_id),
          ) &&
          (currentGroup?.length > 1 || hasSomeGroupNotAllItsOperationsSelected)
        )
          selectedOperations.value = [];
      }

      toggleSelectedOperations(operations, options);

      setSelectableOperations(sortedOngoingOps.value);
    }
    function getGroupDetails(operations: SchedulingOperation[]) {
      const groupOperationsReducedAsAnObject = {
        ...operations[0], // retrieving units from first operation
        ...getOperationsAggregatedQuantities(operations),
      };

      return operationComputedQuantity(groupOperationsReducedAsAnObject, true)
        .fullQuantitiesText;
    }
    function isShownActionsMenu(operations: {op_id?: string}[]): boolean {
      if (!selectedOperationsIDs.value.length) return false;
      return operations.some(
        ({op_id}) => op_id === selectedOperationsIDs.value[0],
      );
    }
    function disconnectResizeObserver() {
      if (resizeObserver.value) resizeObserver.value.disconnect();
    }

    watch(scrollContainerRef, (ref) => {
      disconnectResizeObserver();

      if (!ref) return;

      /**
       * reactive tracking of operations container height
       */
      resizeObserver.value = new ResizeObserver((entries) => {
        const [entry] = entries;
        scrollContainerHeight.value = (
          entry.target as HTMLElement
        ).offsetHeight;
      });

      resizeObserver.value.observe(scrollContainerRef.value);
    });

    onUnmounted(disconnectResizeObserver);

    return {
      getGroupDetails,
      isSelectedOperationsGroup,
      selectOperations,
      pg_ops,
      pg_capa: ref<unknown[]>(null),
      loading: ref<boolean>(false),
      scrollContainerRef,
      pg_min_wip: ref<number>(null),
      pg_max_wip,
      hasOperationsAbove,
      hasOperationsBelow,
      TEST_IDS,
      // counting operations that have mounted
      mountedOps,
      selectedSimulation,
      schedulingCurrentGroupBy,
      canLoadCapa,
      pg_init,
      pg_subscribe,
      userData,
      apiClient,
      parsedPeriod,
      shouldHandleOPDuration,
      sendSchedulingLoadEventToBackend,
      sortedOngoingOps,
      sortedOngoingOpsIds,
      stringifyArray,
      sectorTree,
      getDisplayedMachineValue,
      onClickOutsideColumn,
      operationsActionsMenuAnchorID,
      operationsActionsMenuProps,
      operationsCardsCommonProps,
      scrollContainerWrapperRef,
      priorityRules,
      isManualSortingEnabled,
      variables,
      opsByTicketsGaugeArea,
      orderedTicketsGaugeAreas,
      getOperationProps,
      getTicketsCountByAreaText,
      selectedConwipGroup,
      displayedTicketsGaugesArea,
      getMaxTicketsByArea,
      hasOperatorsOrMachines,
      getTicketsAreaTypeByName,
      reverseOrderedTicketsGaugeAreas,
      openConwipTicketsGaugeSidebar,
      filteredTicketsGaugeAreas,
      onOperationMounted,
      operationsCardsCommonListeners,
      currentPermissions,
      onSelectCard,
      onOperationsGrouped,
      operationsBatches,
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
      unbatchedOngoingOps,
      onMoveOperation,
      havePendingChangesOperations,
      areSavingOperations,
      haveErrorsOnSaveOperations,
      getOperationsAggregatedQuantities,
      selectedOperationsWithBatchID,
      ofsCount,
      operationsSets,
      GROUP_KEY,
      isShownActionsMenu,
      getReadableImportParsingRuleValue,
      scrollContainerHeight,
      sectorWithInferedUnit,
      operationCardEstHeight,
    };
  },
  computed: {
    formattedPgCapa() {
      const {pg_capa, pg_ops} = this;
      if (!pg_ops.length) return "-";
      return numeral(+pg_capa).format("0.[00]");
    },
    simulation() {
      return this.selectedSimulation;
    },

    ongoingOpsTotalNbPieces() {
      const {quantite_op} = this.getOperationsAggregatedQuantities(this.pg_ops);

      return {
        value: quantite_op,
        text: numeral(quantite_op).format("0.[00]"),
      };
    },
  },
  watch: {
    selectedSimulation() {
      this.loadTotalCapa();
    },
    ops: {
      immediate: true,
      handler: function (val: any) {
        this.pg_ops = pgOpsMapFn(Array.from(val || []), {
          removeSmoothedDuplicates: true,
        });
      },
      deep: true,
    },
    scrollContainerHeight(height: number) {
      if (!height) return;

      this.$nextTick(this.updateCalculations);
    },
  },
  created() {
    this.pg_subscriptions = _.debounce(this.pg_subscriptions, 150);
    this.loadTotalCapa = _.debounce(this.loadTotalCapa, 150);
    this.loadFieldValue = _.debounce(this.loadFieldValue, 150);

    usePGWatcher(this);
  },
  methods: {
    pg_subscriptions() {
      if (!this.canLoadCapa) return;
      this.pg_init();
      this.pg_capa = null;
      this.pg_subscribe(["daily_capa", "default_capa"], () => {
        this.loadTotalCapa();
      });
    },
    async loadTotalCapa() {
      if (this.hideSectorBlockItems) return;

      this.loading = true;
      const {sectorTree, userData, simulation, parsedPeriod, canLoadCapa} =
        this;
      const {client_id} = userData || {};
      const {startDate, endDate} = parsedPeriod || {};

      if (!canLoadCapa || !simulation.id) {
        this.loading = false;
        return;
      }
      const params = {
        client_id,
        simulation_id: simulation.id,
        startDate,
        endDate,
        sector: sectorTree,
        load_auto_orga: true,
      };

      const results = await this.apiClient.getCustomDailyTotalCapa(params);
      this.pg_capa = _.get(results, [0, "daily_capa"]) || 0;

      this.loading = false;
    },
    // loads the value for min_wip and max_wip that will determine whether or not a machine center is undercharged/overloaded
    async loadFieldValue() {
      const {userData, sectorTree: sector_tree, simulation} = this;
      const {client_id} = userData || {};
      const defaultDate = "1970-01-01";
      ["min_wip", "max_wip"].forEach((model: string) => {
        const params = {
          client_id,
          startDate: defaultDate,
          endDate: defaultDate,
          sector: sector_tree,
          simulation,
          field: model,
          load_calendar: true,
        };

        /**
         * FIXME: we always return 0 even in the case of non-definition
         */
        this.apiClient.getCustomMSDFieldValue(params).then((result: any) => {
          const {field_value} = result || {};
          this[`pg_${model}`] = field_value;
        });
      });
    },
    insideContainerScrollTo(direction = "top") {
      if (!this.scrollContainerWrapperRef) return;

      this.scrollContainerWrapperRef.scrollTo({
        top:
          direction === "bottom"
            ? 0
            : this.scrollContainerRef.offsetHeight * -1.15,
        behavior: "smooth",
      });
    },
    updateCalculationsDebounced: _.debounce(function (this: any) {
      this.updateCalculations();
    }, 150),
    updateCalculations() {
      const scrollContainer = this.scrollContainerRef;
      const scrollContainerWrapper = this.scrollContainerWrapperRef;

      if (!scrollContainer) return;

      const childrenOperationsList = scrollContainer.querySelectorAll(
        `.${CSS_OPERATION_CARD_CLASS}`,
      );
      const childrenGroupsList = scrollContainer.querySelectorAll(
        ".oplist-wrapper, .operations-cards-group-wrapper__container",
      );

      if (!childrenOperationsList.length && !childrenGroupsList.length) return;

      const OPERATIONS_GROUP_HEIGHT = 70;

      const containerHeight = scrollContainer.offsetHeight;
      const wrapperScrollTop = scrollContainerWrapper.scrollTop;
      const wrapperHeight = scrollContainerWrapper.offsetHeight;

      this.hasOperationsBelow =
        Math.abs(wrapperScrollTop) > this.operationCardEstHeight;

      const childrenHeight =
        childrenOperationsList.length * this.operationCardEstHeight +
        childrenGroupsList.length * OPERATIONS_GROUP_HEIGHT;

      this.hasOperationsAbove =
        wrapperScrollTop === 0
          ? childrenHeight > wrapperHeight
          : Math.abs(wrapperScrollTop) < containerHeight - wrapperHeight;
    },
    sliceToMaxLength(
      str: string,
      maxLength: number,
      forceFullText = false,
    ): string {
      return str?.length < maxLength || forceFullText
        ? str
        : `${toSafeString(str).slice(0, maxLength - 3)}...`;
    },
    updateTheoricDate(data: any) {
      const {op_id, theoric_date} = data;
      this.pg_ops = this.pg_ops.map((x: any) =>
        x.op_id === op_id ? {...x, theoric_date} : x,
      );
    },
  },
});
</script>
<style scoped lang="scss">
.scroll-x-hidden {
  overflow-x: hidden !important;
}
.machine {
  display: inline-block;
  &-column {
    --column-padding: 8px;

    background: rgb(var(--v-theme-grey-50));
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;

    & .sticky-control {
      position: sticky;
      width: 100%;
      padding: 6px;
      border: 1px solid rgb(var(--v-theme-newSelected));
      background: rgb(var(--v-theme-newSubBackground));
      color: rgb(var(--v-theme-newSubText)) !important;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;

      &.top-control {
        top: 0;
        border-bottom-width: 2px;
      }

      &.bottom-control {
        top: 450px;
        border-top-width: 2px;
      }
    }

    & :deep(.conwip-tickets-gauge-tickets-area) {
      padding: var(--column-padding);
    }

    & :deep(.conwip-tickets-gauge-tickets-area__content) {
      display: flex;
      flex-direction: column-reverse;
    }

    & :deep(.conwip-tickets-gauge-tickets-area__header) {
      padding: 0 4px;
    }
  }
  &-inprogress {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    & .operations-scroll-container-wrapper {
      height: 0px;
      flex: 1 1 auto;
      overflow-y: auto;
      min-height: 0px;
      display: flex;
      flex-direction: column-reverse;
      overflow-x: hidden;

      &.keep-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      & .operations-scroll-container {
        border-radius: 8px;
        &.overflow-auto {
          overflow: auto;
        }
      }

      & .operation-card {
        padding: 0 !important;
      }
    }
  }

  &-ended {
    border: 1px solid rgb(var(--v-theme-newSelected));
    border-radius: 0px 0px 8px 8px;
    background-color: rgb(var(--v-theme-newLayerBackground));
    height: 320px;
  }
}

.machine-column :deep(.operation-card .scheduling-operation-card__content) {
  border-radius: 8px;
}

.machine-column:not(.en-cours-column__is-conwip) {
  & .operations-scroll-container-wrapper {
    padding: var(--column-padding);
  }

  div:not(.operations-cards-group-wrapper__content)
    > .scheduling-operation-card__en-cours {
    --wip-column-operations-spacing: 8px;

    &:last-child :deep(.scheduling-operation-card__content) {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    &:first-child :deep(.scheduling-operation-card__content) {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }

    &:deep(.scheduling-operation-card__content:not(.selected-card)) {
      border-top: 1px solid rgb(var(--v-theme-newSelected));
    }

    &:not(:first-child) {
      margin-top: var(--wip-column-operations-spacing);
      border-radius: 8px;
    }
  }
}

.en-cours-column__is-conwip {
  .conwip-tickets-gauge-placeholder + & {
    & .operations-scroll-container-wrapper {
      padding: var(--column-padding);
    }
  }

  & .scheduling-operation-card__en-cours {
    &:deep(.scheduling-operation-card__content) {
      margin-top: 8px;
    }
  }

  & .operations-scroll-container {
    border: none !important;
    border-radius: 0 !important;
  }
}

[id^="operations-actions-menu__anchor-"] {
  position: relative;

  &:deep(~ .operation-card-actions-menu) {
    --spacing: 8px;

    top: initial !important;
    bottom: var(--sector-block--height);
    padding: 6px;

    &.display-right {
      transform: translateX(var(--spacing));
    }

    &.display-left {
      transform: translateX(calc(var(--spacing) * -1));
    }
  }
}
</style>
<style lang="scss">
.operations-scroll-container-wrapper {
  & .oplist-wrapper {
    padding: 0 !important;

    &:last-child {
      .operation-card:last-child .scheduling-operation-card__content {
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }
    }

    &:first-child {
      & .oplist-title {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }

    &:last-child {
      & .oplist-title:not(.open) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .oplist-container {
      padding-bottom: 8px;
    }
  }

  & .operation-card {
    & > .v-card {
      border-radius: 0;
      border: none !important; /* removing the previous style applied so that only the following is */
      cursor: unset;
    }

    &:not(:first-child) {
      & > .v-card {
        border-top: 2px solid rgb(var(--v-theme-newSelected)) !important;
      }
    }
  }
}

.operations-scroll-container {
  border-top: 1px solid transparent !important;
}

.machine-inprogress
  .operations-scroll-container
  .operations-cards-group-wrapper__container {
  border: 1px solid rgb(var(--v-theme-newSelected));

  &:not(:first-child) {
    margin-top: 8px;
  }

  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.operations-cards-group-wrapper__container {
  .operations-cards-group-wrapper__content {
    padding: 0 8px 8px;

    & .operation-card {
      & .scheduling-operation-card__content {
        border-radius: 0;
      }
    }
  }
}

.operations-cards-group-wrapper__container:first-child:last-child {
  border-radius: inherit;
  overflow: hidden;
}

.machine-status {
  svg {
    stroke-width: 3px;
  }
}
</style>
